<section class="fixed-top" *ngIf="isMobileView">
  <nav class="navbar navbar-expand-lg navbar-light bg-white shadow-sm">
    <div class="container py-3 d-block">
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col text-left">
          <span class="navbar-toggler-icon" (click)="toggleNavbar()"></span>
        </div>
        <div class="col text-center">
          <a class="navbar-brand mx-auto"
            [routerLink]="role === 'Management' ? '/admin/dashboard' : role === 'Franchisor' ? '/dashboard' : '/home'">
            <img src="assets/images/f1.png" alt="Logo" class="logo" width="120" />
          </a>
        </div>
        <div class="col text-end">
          <div class="navbar-icons" *ngIf="role !== 'Management'"> 
            <button *ngIf="role !== 'Franchisor'" class="btn btn-link p-1 p-lg-2" aria-label="Search" (click)="toggleSearchBar();">
              <img src="assets/images/ai-search.svg" alt="Search" class="search-icon" />
            </button>
            <button *ngIf="role === 'Franchisee'" type="button" class="btn btn-md p-1 p-lg-3 btn-link position-relative" (click)="openCart()">
              <img src="assets/images/shopping-cart.svg" alt="Shopping Cart" class="shopping-cart" style="width: 20px; height: 20px;" />
              <span *ngIf="cartQuantity > 0" class="position-absolute top-25 start-80 translate-middle badge rounded-pill bg-danger">
                {{ cartQuantity }}
                <span class="visually-hidden">items in cart</span>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="collapse navbar-collapse" [ngClass]="{'show': !isNavbarCollapsed}">
            <ul class="navbar-nav mr-auto" *ngIf="role !== 'Management'">
              <li *ngIf="isAuthenticated" class="nav-item text-center">
                <div class="rounded-circle mx-auto" style="width: 60px; height: 60px; overflow: hidden;"><img *ngIf="role !== 'Management'" [src]="profile?.image ? profile?.image.url : './assets/images/avatars/2.jpg'" alt="{{profile?.name}}" style="width: 100%; height: 100%; object-fit: cover;" /></div>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link text-dark" routerLink="/profile" (click)="toggleNavbar()">Welcome, {{profile?.name}}</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" routerLink="/" (click)="toggleNavbar()">Home</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Franchisee'" class="nav-item">
                <a class="nav-link" routerLink="/profile" (click)="toggleNavbar()">My Profile</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Franchisee'" class="nav-item">
                <a class="nav-link" routerLink="/order" (click)="toggleNavbar()">My Orders</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Franchisee'" class="nav-item">
                <a class="nav-link" routerLink="/wishlist" (click)="toggleNavbar()">My Wishlist</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Franchisor'" class="nav-item">
                <a class="nav-link" routerLink="/brands" (click)="toggleNavbar()">My Brands</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Franchisor'" class="nav-item">
                <a class="nav-link" routerLink="/orders" (click)="toggleNavbar()">Applicants</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Sales'" class="nav-item">
                <a class="nav-link" routerLink="/sales" (click)="toggleNavbar()">Quick Checkout</a>
              </li>
              <li *ngIf="isAuthenticated && profile?.activeRole === 'Sales'" class="nav-item">
                <a class="nav-link" routerLink="/sales/leads" (click)="toggleNavbar()">My Leads</a>
              </li>
              <li *ngIf="profile?.activeRole === 'Franchisee'" class="nav-item">
                <a class="nav-link" routerLink="/catalog" (click)="toggleNavbar()">Catalog</a>
              </li>
            </ul>
            <ul class="navbar-nav mr-auto" *ngIf="role === 'Management'">
              <li class="nav-item">
                <a class="nav-link" routerLink="/admin/dashboard" (click)="toggleNavbar()">Dashboard</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/admin/kyc" (click)="toggleNavbar()">Users</a>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/admin/kyb" (click)="toggleNavbar()">Brands</a> 
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/admin/tags" (click)="toggleNavbar()">Tags</a> 
              </li>
              <!-- <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" routerLink="/profile" (click)="toggleNavbar()">My Profile</a>
              </li> -->
            </ul>
            <ul class="navbar-nav ml-auto">
              <li *ngIf="!isAuthenticated && role !== 'Management'" class="nav-item">
                <button class="btn btn-primary me-2" routerLink="/login" (click)="toggleNavbar()">Login</button>
                <button class="btn btn-outline-primary" routerLink="/register" (click)="toggleNavbar()">Register</button>
              </li>
              <li *ngIf="isAuthenticated" class="nav-item">
                <a class="nav-link" (click)="logout()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row collapse-search" id="searchBar" *ngIf="role !== 'Management'">
        <div class="col-12">
          <div class="collapse c-navbar-collapse" id="navbarNav" [ngClass]="{'show': !isSearchBarCollapsed}">
            <form class="form-inline my-2 my-lg-0 px-2 w-100" (submit)="onSearch($event)">
              <input class="form-control mr-sm-2 w-100" type="search"  [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" placeholder="Coba ketikkan 'cepat balik modal'" (input)="onInputChange($event)" aria-label="Search" />
            </form>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div class="overlay" [ngClass]="{'show': !isNavbarCollapsed}" (click)="toggleNavbar()"></div>
  <div class="overlay" [ngClass]="{'show': !isSearchBarCollapsed}" (click)="toggleSearchBar()" *ngIf="role !== 'Management'"></div>
</section>

<section class="sticky-top bg-white shadow-sm" *ngIf="!isMobileView">
  <div [ngClass]="role === 'Franchisor' && isDashboard ? 'container-fluid shadow-sm' : 'container'">
    <div class="row">
      <div [ngClass]="role === 'Franchisor' && isDashboard ? 'col-lg-10 ms-auto' : 'col-lg-12 ms-auto'">
        <header class="d-flex flex-nowrap align-items-center justify-content-center justify-content-lg-between gap-3 py-2">
          <div class="col-lg-3 mb-lg-0" *ngIf="!(role === 'Franchisor' && isDashboard)">
            <a [routerLink]="role === 'Management' ? '/admin/dashboard' : role === 'Franchisor' ? '/dashboard' : role === 'Sales' ? '/sales' : '/home'" class="d-inline-flex link-body-emphasis text-decoration-none">
              <img src="assets/images/f1-trans.png" alt="Franchise One Logo" class="logo" style="width: 100%; max-width: 150px;" />
            </a>
          </div>
          <div class="search flex-grow-1" *ngIf="role !== 'Management' && role !== 'Sales'">
            <form class="d-flex" (submit)="onSearch($event)">
              <div class="input-group w-100 border rounded">
                <input type="search" class="form-control focus-ring focus-ring-light border-0 fs-6 p-2" [(ngModel)]="searchQuery" [ngModelOptions]="{standalone: true}" placeholder="Coba ketikkan 'cepat balik modal'" (input)="onInputChange($event)" aria-label="Search" />
                <button type="submit" class="btn btn-white focus-ring focus-ring-light">
                  <img src="assets/images/ai-search.svg" alt="Search" class="search-icon" />
                </button>
              </div>
            </form>
          </div>
          <div *ngIf="!isAuthenticated" class="d-flex justify-content-between align-items-center text-end">
            <button type="button" class="btn btn-md px-4 py-2 btn-primary me-2" routerLink="/login">Login</button>
            <button type="button" class="btn btn-md px-4 py-2 btn-outline-primary" routerLink="/register">Register</button>
          </div>
          <div *ngIf="isAuthenticated" class="d-flex justify-content-between align-items-center text-end">
            <button *ngIf="role === 'Franchisee'" type="button" class="btn btn-md p-3 btn-link position-relative" (click)="openCart()">
              <img src="assets/images/shopping-cart.svg" alt="Shopping Cart" class="shopping-cart" style="width: 20px; height: 20px;" />
              <small>
                <small *ngIf="cartQuantity > 0" class="position-absolute top-25 start-80 translate-middle badge rounded-pill bg-danger">
                  {{ cartQuantity }}
                  <span class="visually-hidden">items in cart</span>
                </small>
              </small>
            </button>
            <div class="nav-item py-1"><div class="vr h-100 mx-4 text-body text-opacity-75"></div></div>
            <ng-container *ngTemplateOutlet="userDropdown"></ng-container>
          </div>
        </header>
      </div>
    </div>
  </div>
</section>

<ng-template #userDropdown>
  <div ngbDropdown>
    <button ngbDropdownToggle class="btn btn-link text-primary text-decoration-none py-0 pe-0 d-flex align-items-center" aria-label="Open user menu">
      <div class="rounded-circle" style="width: 40px; height: 40px; overflow: hidden;"><img *ngIf="role !== 'Management'" [src]="profile?.image ? profile?.image.url : './assets/images/avatars/2.jpg'" alt="{{profile?.name}}" style="width: 100%; height: 100%; object-fit: cover;" /></div>
      <span *ngIf="role !== 'Management'" class="ms-3">{{profile?.name}}</span>
      <span *ngIf="role === 'Management'" class="ms-3">Franchise One</span>
    </button>
    <div ngbDropdownMenu class="w-auto">
      <a *ngIf="role === 'Franchisee'" class="dropdown-item" role="button" (click)="openProfile()">
        <fa-icon [icon]="icons.faUser" class="me-2"></fa-icon>
        My Profile
      </a>
      <a *ngIf="role === 'Franchisee'" class="dropdown-item" role="button" (click)="openOrders()">
        <fa-icon [icon]="icons.faListCheck" class="me-2"></fa-icon>
        My Orders
      </a>
      <a *ngIf="role === 'Franchisee'" class="dropdown-item" role="button" (click)="openWishlist()">
        <fa-icon [icon]="icons.faHeart" class="me-2"></fa-icon>
        My Wishlist
      </a>
      <a *ngIf="role === 'Franchisor'" class="dropdown-item" role="button" (click)="openBrands()">
        <fa-icon [icon]="icons.faHeart" class="me-2"></fa-icon>
        My Brands
      </a>
      <a *ngIf="role === 'Sales'" class="dropdown-item" role="button" (click)="openSales()">
        <fa-icon [icon]="icons.faCashRegister" class="me-2"></fa-icon>
        Quick Checkout
      </a>
      <a *ngIf="role === 'Sales'" class="dropdown-item" role="button" (click)="openLeads()">
        <fa-icon [icon]="icons.faHandHoldingDollar" class="me-2"></fa-icon>
        My Leads
      </a>
      <a *ngIf="role === 'Management'" class="dropdown-item" role="button" (click)="openKYC()">
        <fa-icon [icon]="icons.faUser" class="me-2"></fa-icon>
        Users
      </a>
      <a *ngIf="role === 'Management'" class="dropdown-item" role="button" (click)="openKYB()">
        <fa-icon [icon]="icons.faHeart" class="me-2"></fa-icon>
        Brands
      </a>
      <a *ngIf="role === 'Management'" class="dropdown-item" role="button" (click)="openTags()">
        <fa-icon [icon]="icons.faHashtag" class="me-2"></fa-icon>
        Tags
      </a>
      <a class="dropdown-item" role="button" (click)="logout()">
        <fa-icon [icon]="icons.faRightFromBracket" class="me-2"></fa-icon>
        Logout
      </a>
    </div>
  </div>
</ng-template>
