<nav id="sidebar" class="col-lg-2 d-lg-block bg-white sidebar">
  <div class="sidebar-header text-center">
    <a class="navbar-brand mx-auto"
      [routerLink]="role === 'Management' ? '/admin/dashboard' : role === 'Franchisor' ? '/dashboard' : '/home'">
      <img src="../../../../assets/images/f1.png" alt="Logo" class="logo" width="120" />
    </a>
  </div>
  <ul class="nav flex-column py-5 px-3">
    <li class="nav-item py-2">
      <a class="nav-link active" [routerLink]="'/dashboard'">Dashboard</a>
    </li>
    <li class="nav-item py-2">
      <a class="nav-link" [routerLink]="'/brands'">Brands &amp; Packages</a>
    </li>
    <li class="nav-item py-2">
      <a class="nav-link" [routerLink]="'/orders'">Applicants</a>
    </li>
    <li class="nav-item py-2">
      <a class="nav-link" [routerLink]="'/control'">Control Tower</a>
    </li>
    <!-- <li class="nav-item py-2">
      <a class="nav-link" [routerLink]="'/profile'">Profile</a>
    </li> -->
    <li class="nav-item py-2">
      <a class="nav-link" (click)="logout()" style="cursor: pointer;">Logout</a>
    </li>
  </ul>
</nav>